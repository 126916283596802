@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&family=Ubuntu:wght@500&display=swap');
@import url("https://use.typekit.net/hwe2eom.css");
:root {
    --primary-red: #bf1e2d;
    --primary-blue: #293a61;
    --main-bg: #f0f0f0;
  }

.titleText {
    font-family: 'Ubuntu', sans-serif;
}

.default-page {
    background-color: rgb(240, 240, 240);
}

.home-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 0px;
    padding-top: 0px;
    padding: 0px;
    background-color: var(--main-bg);
}

.contact-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 0px;
    padding-top: 0px;
    padding: 0px;
    background-color: var(--main-bg);
}

.bottom-pic-container {
    height: 20vw;
    width: 95vw;
    display: flex;
    justify-content: center;
}

.home-pic {
    padding: 10px;
}

.vendor-pic-container {
    height: 20vw;
    width: 40vw;
    display: flex;
    justify-content: center;
}

.vendor-pic {
    height: 100%;
    padding: 10%;
    display: flex;
}

.dual {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    width: 80vw;
}

.dual-pic-container{
    min-height: 40vw;
    background: url("./imgs/interior_ventilation.jpeg");
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: 50%;
}
.dual-pic{
    height:35vw;
    background-color: var(--primary-blue);
}

.dual-header {
    font-family: "interstate", sans-serif; 
    color: var(--primary-blue);
    font-size: min(36px, 9vw);
    text-align: left;
}

.dual-text {
    font-size: min(14pt, 2.7vw);
    text-align: left;
}

.homepage-bgimage {
    background: url("./imgs/exterior_construction.webp");
    position: relative;
    height: min(max(50vw, 250px),500px);
    background-position: center;
    background-size: cover;
  }

  .aboutpage-bgimage {
    background: url("./imgs/aab_fencing.webp");
    position: relative;
    height: 38vw;
    background-position: center;
    background-size: cover;
  }

  .servicespage-bgimage {
    background: url("./imgs/interior_lighting.jpeg");
    position: relative;
    height: 30vw;
    background-position: center;
    background-size: cover;
    background-position-y: 58%;
}

.healthcarepage-bgimage {
    background: url("./imgs/mri_room_1.png");
    position: relative;
    height: 38vw;
    background-position: center;
    background-size: cover;
  }

  .commercialpage-bgimage {
    background: url("./imgs/clay_ground.jpeg");
    position: relative;
    height: 38vw;
    background-position: center;
    background-size: cover;
  }

  .homepage-bgimage2 {
    background: url("./imgs/blue_background_bars.png");
    height: min(300px, 80vw);
    background-position: center;
    background-repeat: no-repeat;
    width: 80vw;
  }

  .servicespage-bgimage2 {
    background: url("./imgs/background_red_building.png");
    height: min(300px, 80vw);
    background-position: center;
    background-repeat: no-repeat;
    width: 80vw;
  }

  .aboutpage-bgimage2 {
    background-color: var(--primary-blue);
    width: 80vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
  }

  .about-img-container {
    background-color: var(--main-bg);
    padding:10px;
  }

.header-container {
    width:100%;
    /* left: 20%; */
    background: var(--primary-blue);
    margin: 0px 0px -16px 0px;
    /* padding: 0px 0px 10px 0px; */
}

.header-text {
    font-family: "interstate", sans-serif; 
    font-style: italic;
    color:rgb(255, 255, 255);
    font-size: min(6vw, 50px);
    padding: 1vw;
}

.header-subtext {
    font-family: "interstate", sans-serif; 
    font-weight: 700;
    color:rgb(255, 255, 255);
    font-size: min(4vw, 16px);
    padding: 0vw 1vw 1vw 1vw;
}

.home-header {
    font-family: "interstate", sans-serif; 
    font-weight: 700;
    font-style: normal;
    color: var(--primary-blue);
    position: relative;
    font-size: min(40px, 9vw);
    padding-left: 10vw;
    padding-right: 10vw;
    text-align: left;
}

.home-text {
    font-family: "interstate", sans-serif; 
    color:rgb(235, 235, 235);
    font-size: 20px;
    width: max(200px, 40vw);
    margin: auto;
    text-align: center;
    position: relative;
    top: min(6vw, 200px);
}

.home-button {
    font-family: "interstate", sans-serif;
    font-weight: 700; 
    position: relative;
    padding: 10px;
    background: var(--primary-red);
    color: rgb(255, 255, 255);
    top: min(10vw, 240px);
    transition: 0.3s;
    text-decoration: none;
    border-radius: 5px;
}

.home-button:hover {
    background: rgb(255,255,255);
    color: var(--primary-red);
    border-radius: 5px;
}

.services-button {
    font-family: "interstate", sans-serif;
    font-weight: 700; 
    position: relative;
    padding: 10px;
    background: var(--primary-blue);
    color: rgb(255, 255, 255);
    top: min(10vw, 240px);
    transition: 0.3s;
    text-decoration: none;
    border-radius: 5px;
}

.services-button:hover {
    background: rgb(255,255,255);
    color: var(--primary-blue);
    border-radius: 5px;
}

.main-text {
    color: rgb(55, 55, 55);
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: min(14pt, 4vw);
    text-align: left;
}

.bullet-text {
    color: rgb(85, 85, 85);
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: min(11pt, 2vw);
    text-align: left;
}

.home-section-container {
    padding:10px;
    align-content: left;
}

.about-section-container {
    padding:10px;
    align-content: left;
}

.about-header {
    font-family: "interstate", sans-serif; 
    color: var(--primary-blue);
    position: relative;
    font-size: min(40px, 9vw);
    padding-left: 10vw;
    padding-right: 10vw;
    text-align: left;
}
.about-section-header {
    font-family: "interstate", sans-serif; 
    color: var(--primary-blue);
    position: relative;
    font-size: min(28px, 9vw);
    padding-left: 2vw;
    padding-right: 2vw;
    text-align: left;
}

.about-section-text {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: large;
    text-align: left;
}

.contact-section-text {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: medium;
    text-align: left;
}

.about-text {
    color: var(--main-bg);
    font-size: 23px;
}

.vendor-section-header {
    font-family: "interstate", sans-serif; 
    color: var(--primary-blue);
    position: relative;
    font-size: 24px;
    padding-left: 10vw;
    padding-right: 10vw;
    text-align: center;
}

.contact-section-header {
    font-family: "interstate", sans-serif; 
    color: var(--primary-blue);
    position: relative;
    font-size: 36px;
    padding-left: 2vw;
    padding-right: 2vw;
    text-align: left;
}

.banner {
    padding: "10px";
    position: relative;
    z-index: 1;
    background-color: var(--main-bg);
}

.banner-link {
    color: var(--primary-blue);
    font-size: 20px;
    font-family: "interstate", sans-serif; 
    font-weight: 700;
    margin-left: 10px;
    border-radius: 5px;
}

.banner-link:hover {
    background-color: var(--primary-blue);
    color: #ffffff;
    border-radius: 5px;
}

.footer-link-text {
    color:rgb(255,255,255);
    text-decoration: none;
    font-family: "interstate", sans-serif;
    font-weight: 700; 
    justify-content: center;
    font-size: large;
}

.footer-link-text:hover {
    color: var(--primary-red);
}

.colorBanner {
    background-color: var(--main-bg);
    padding-left: '1000px';
    padding-right: '100px';
}

html {
    height: 100%;
}

.footer-item-container {
    padding-bottom: 20px;
    text-align: center;
}

.footer-text-container {
    display: flex;
    flex-flow: row wrap;   
    padding-left: 0%; 
    padding-right: 0%;
    margin: auto;
    width: 90%; 
}

.logo-container {
    height: min(120px, 25vw);
    display: flex;
    justify-content: center;
}

.input-container{
    top: -4px;
	position:relative;
	margin-bottom:25px;
    margin-left: 32px;
    border-radius: 4px;
    background-color: rgb(230,230,230);
    width:40vw;
}

.input-container label{
	position:absolute;
	top:-16px;
	left:0px;
	font-size:12px;
    color: var(--primary-blue);
    transition: all 0.5s ease-in-out;
	
}

.input-container input{ 
  border:0;
  border-bottom:1px solid var(--primary-blue);  
  background:transparent;
  width:100%;
  padding:8px 0 5px 0;
  padding-left: 10px;
  font-size:16px;
  border-radius: 4px;
  color: var(--primary-blue);
}

.input-container input:focus{ 
 border:none;	
 outline:none;
 border-bottom:1px solid var(--primary-red);	
 background-color: rgb(210,210,210);

}

.textarea-container{
    top: -4px;
	position:relative;
	margin-bottom:25px;
    margin-left: 32px;
    width:40vw;
}

.textarea-container label{
	position:absolute;
	top:-4px;
	left:0px;
	font-size:12px;
    color: var(--primary-blue);
    transition: all 0.5s ease-in-out;
}

.textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    border: 1px solid var(--primary-blue);
    border-radius: 4px;
    background-color: rgb(230,230,230);
    font-size: 16px;
    resize: none;
  }

  .textarea:focus{
    outline: none !important;
    border:1px solid var(--primary-red);
    box-shadow: 0 0 2px var(--primary-blue);
    background-color: rgb(210,210,210);
  }

  .contact-submit {
    font-family: "interstate", sans-serif; 
    position: relative;
    font-size: 24px;;
    background: var(--primary-red);
    border: 0px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    transition: 0.3s;
    text-decoration: none;
    padding: 10px 30px;
}

.contact-submit:hover {
    background: rgb(255,255,255);
    color: var(--primary-red);
    outline:2px solid var(--primary-red);
}

.carousel-pic {
    /* height: min(70vw, 300px); */
    height: min(50vw, 500px);
    margin: auto;
    object-fit: contain;
    justify-content: center;
    justify-self: center;
    background-color: #293a61;
}

.carousel-container {
    width: 80vw;
    /* height: min(80vw, 400px); */
    margin: auto;
    justify-content: center;
    justify-self: center;
    background-color: #717171;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
   
.carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}